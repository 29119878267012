<h1>Choose Video</h1>

<div class="layout">
  <div class="list" [class.has-selection]="previewState !== 'empty'">
    <mat-form-field class="search" appearance="outline" floatLabel="always">
      <mat-label>Search</mat-label>
      <mat-icon matPrefix>search</mat-icon>
      <input type="text" matInput [(ngModel)]="query" />
    </mat-form-field>

    <div class="field-row">
      <div class="spacer"></div>
      <mat-checkbox [(ngModel)]="autoplay">Autoplay</mat-checkbox>
    </div>

    <div class="results">
      @if (resultsState === 'loading') {
        <app-spinner [inline]="true" class="text-inline"></app-spinner>
        Loading recent videos...
      }
      @if (resultsState === 'searching') {
        <app-spinner [inline]="true" class="text-inline"></app-spinner>
        Searching...
      }
      @if (resultsState === 'search') {
        @for (result of searchResults; track result) {
          <a
            (click)="selectResult(result)"
            class="search-result"
            [class.selected]="production?.id === result.uuid && item?.id === result.subitem_uuid"
            >
            <div class="result-thumbnail" [responsiveBackgroundImage]="result.image_url"></div>
            <div class="details">
              <ul class="facts">
                <li>{{ result.date | date: 'longDate' }}</li>
                <li>
                  <div class="show">
                    <div class="icon" [responsiveBackgroundImage]="result.avatar_url"></div>
                    {{ result.group_title }}
                  </div>
                </li>
              </ul>
              <div class="title">{{ result.title }}</div>
            </div>
          </a>
        }
      }
      @if (resultsState === 'recent') {
        @for (episode of recentProductions; track episode; let i = $index) {
          <div class="production">
            <tyt-production-view
              [production]="episode"
              mode="episodes"
              [showFeedTag]="!episode.show?.use_titles || episode.title !== (episode.show.name + ': ' + (episode.date | date: 'longDate'))"
              [includeShowName]="true"
              [includeItems]="false"
              [style.animation-delay]="(i * 100)+'ms'">
            </tyt-production-view>
            <mat-tab-group mat-stretch-tabs="false" class="production-content">
              @if (episode.full_length_vods.length > 0) {
                <mat-tab label="Episode" labelClass="episodes">
                  <tyt-production-view
                    mode="episodes"
                    [playRemote]="true"
                    [playInline]="true"
                    (playClicked)="select(episode, $event)"
                    [production]="episode"
                    [currentlyPlayingItem]="item"
                    [includeMetadata]="false"
                    [showCommentsCount]="false"
                    [showFeedTag]="false"
                    [style.animation-delay]="(i * 100)+'ms'"
                  ></tyt-production-view>
                </mat-tab>
              }
              @if (episode.vod_clips.length > 0) {
                <mat-tab label="Clips" labelClass="clips">
                  <tyt-production-view
                    mode="clips"
                    [playRemote]="true"
                    [playInline]="true"
                    (playClicked)="select(episode, $event)"
                    [production]="episode"
                    [currentlyPlayingItem]="item"
                    [includeMetadata]="false"
                    [showCommentsCount]="false"
                    [showFeedTag]="false"
                  [style.animation-delay]="(i * 100)+'ms'"></tyt-production-view>
                </mat-tab>
              }
              @if (episode.full_length_podcasts.length > 0) {
                <mat-tab label="Podcast" labelClass="podcasts">
                  <tyt-production-view
                    mode="podcasts"
                    [playRemote]="true"
                    [playInline]="true"
                    (playClicked)="select(episode, $event)"
                    [production]="episode"
                    [currentlyPlayingItem]="item"
                    [showCommentsCount]="false"
                    [includeMetadata]="false"
                  [style.animation-delay]="(i * 100)+'ms'"></tyt-production-view>
                </mat-tab>
              }
            </mat-tab-group>
          </div>
        }
      }
    </div>
  </div>
  <div class="preview">
    @if (previewState === 'empty') {
      <em>Choose a video from the results pane to preview.</em>
    }
    @if (previewState === 'loading') {
      <app-spinner></app-spinner>
    }
    @if (previewState === 'ready') {
      <div class="back-to-search">
        <button mat-button (click)="clearSelection()">
          <mat-icon>arrow_left</mat-icon>
          Back to Results
        </button>
      </div>
      <div class="button-row video-actions">
        <div class="spacer"></div>
        <a mat-button [url]="urlForVideo(production, item)" target="_blank">
          <mat-icon>open_in_new</mat-icon>
          Open
        </a>
        <a mat-button [url]="editUrlForVideo(production, item)" target="_blank">
          <mat-icon>edit</mat-icon>
          Edit
        </a>
        <button mat-raised-button color="primary" (click)="choose()" matTooltip="Choose this video and close the dialog">
          <mat-icon>done</mat-icon>
          Choose
        </button>
      </div>
      @if (production && !productionLoading && item) {
        <tyt-media-view
          [inEditor]="true"
          [production]="production"
          [show]="showForProduction(production)"
          [item]="item"
          [mode]="playlistForItem(production, item)"
          [showComments]="true"
          [autoplay]="autoplay"
          [showDescription]="true"
          [showPlaylist]="false"
          [showSocialButtons]="false"
          [user]="user">
        </tyt-media-view>
      }
    }
  </div>

</div>