import { Component, inject } from '@angular/core';
import { ApiPodcast, ApiProduction, ApiSearchResult, ApiShow, ApiUser, ApiVOD, ProductionsApi, SearchApi, ShowsApi } from '@tytapp/api';
import { DialogComponent, NamedDialog } from '@tytapp/common';
import { ProductionsService } from '@tytapp/common-ui';
import { UserService } from '@tytapp/user';

@NamedDialog('video-picker')
@Component({
    selector: 'tyt-video-picker-dialog',
    templateUrl: './video-picker.component.html',
    styleUrls: ['./video-picker.component.scss']
})
export class VideoPickerDialogComponent extends DialogComponent {
    private showsApi = inject(ShowsApi);
    private productionsApi = inject(ProductionsApi);
    private searchApi = inject(SearchApi);
    private productionsService = inject(ProductionsService);
    private userService = inject(UserService);

    private shows: ApiShow[] = [];

    production: ApiProduction;
    productionLoading = false;
    item: ApiVOD | ApiPodcast;
    _query: string = '';

    autoplay = false;
    searchResults: ApiSearchResult[];
    recentProductions: ApiProduction[];

    get user() { return this.userService?.user; }

    get query() {
        return this._query;
    }

    searchTimeout;
    set query(value) {
        this._query = value;
        this.searching = true;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => this.loadResults(), 1000);
    }

    get previewState() {
        if (this.productionLoading)
            return 'loading';
        if (this.production)
            return 'ready';

        return 'empty';
    }

    get resultsState() {
        if (this.searching)
            return 'searching';
        if (this.searchResults)
            return 'search';
        if (this.recentProductions)
            return 'recent';

        return 'loading';
    }

    clearSelection() {
        this.production = undefined;
        this.item = undefined;
        this.productionLoading = false;
    }

    editUrlForVideo(production: ApiProduction, item: ApiVOD | ApiPodcast) {
        return `/watch/edit/${production.id}/${this.playlistForItem(production, item)}/${item.id}`;
    }

    urlForVideo(production: ApiProduction, item: ApiVOD | ApiPodcast) {
        return this.productionsService.getWatchUrlSync(production, this.playlistForItem(production, item), item);
    }

    playlistForItem(production: ApiProduction, item: ApiVOD | ApiPodcast) {
        if (!production)
            return undefined;
        if (production.full_length_vods.some(x => x.id === item.id))
            return 'episodes';
        if (production.vod_clips.some(x => x.id === item.id))
            return 'clips';
        if (production.full_length_podcasts.some(x => x.id === item.id))
            return 'podcasts';
        if (production.audio_clips.some(x => x.id === item.id))
            return 'audio-stories';
    }

    searching = false;

    async loadResults() {
        if (this.query) {
            this.searchResults = await this.searchApi.search({
                content_type: 'media',
                query: this.query
            }).toPromise();
            this.searching = false;
        } else {
            this.searchResults = undefined;
            this.searching = false;
        }
    }

    callback: (production: ApiProduction, item: ApiVOD | ApiPodcast) => void;

    async init(callback: (production: ApiProduction, item: ApiVOD | ApiPodcast) => void) {
        this.callback = callback;
        this.shows = await this.showsApi.all().toPromise();
        this.recentProductions = await this.productionsApi.all('published').toPromise();
    }

    async selectResult(result: ApiSearchResult) {
        this.productionLoading = true;
        this.production = await this.productionsApi.get(result.uuid).toPromise();
        this.productionLoading = false;
        this.item = this.itemFromProduction(this.production, result.subitem_uuid);
    }

    async select(production: ApiProduction, item: ApiVOD | ApiPodcast) {
        this.production = production;
        this.productionLoading = true;
        this.production = await this.productionsApi.get(production.id).toPromise();
        this.productionLoading = false;
        this.item = this.itemFromProduction(this.production, item.id);
    }

    itemFromProduction(production: ApiProduction, itemID: string) {
        if (!production)
            return undefined;

        return production.full_length_vods.find(x => x.id === itemID)
            ?? production.vod_clips.find(x => x.id === itemID)
            ?? production.full_length_podcasts.find(x => x.id === itemID)
            ?? production.audio_clips.find(x => x.id === itemID)
        ;
    }
    showForProduction(production: ApiProduction): ApiShow {
        return this.shows.find(x => x.id === production.show.id) ?? production.show;
    }

    choose() {
        if (!this.production || !this.item)
            return;

        this.callback(this.production, this.item);
        this.close();
    }
}